.login {
  display: grid;
  place-items: center;
  height: 100vh;
  width: 100%;
}
.login > button {
  width: 300px;
  background-color: #8160a0;
  color: white;
  font-weight: 800;
}

.login > button:hover {
  background-color: gray;
  color: rgb(190, 231, 190);
  cursor: pointer;
}
.login > div > img {
  object-fit: contain;
  height: 100px;
}
