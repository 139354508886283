.message {
  display: flex;
  align-items: center;
  padding: 20px;
  color: white;
}

.message__info {
  margin-left: 20px;
}
.message__timeStamp {
  color: gray;
  margin-left: 20px;
  font-size: x-small;
}
