.sidebarChannel:hover > h4 {
  color: white;
  background-color: #40464b;
}

.sidebarChannel > h4 {
  display: flex;
  padding-left: 50px;
  align-items: center;
  background-color: #2f3135;
  color: gray;
  cursor: pointer;
}
.sidebarChannel__hash {
  font-size: 30px;
  padding: 8px;
}
