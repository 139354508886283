.chat {
  display: flex;
  flex-direction: column;
  flex: 0.75;
  background-color: #363a3f;
  height: 100vh;
}
.chat__messages {
  flex: 1;
}
.chat__input {
  color: lightgray;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-radius: 5px;
  margin: 20px;
  border-top: 1px solid gray;
  background-color: #474b53;
}
.chat__input > form > input {
  padding: 15px;
  background: transparent;
  width: 100%;
  border: none;
  outline: 0;
  color: white;
  font-size: large;
}

.chat__input > form {
  flex: 1;
}
.chat__inputButton {
  display: none;
}
.chat__inputIcons > .MuiSvgIcon-root {
  padding: 5px;
}
